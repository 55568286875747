body, .App {
  margin: 0;
  padding: 0;
  font-family: 'Caudex', sans-serif;
  background-color: black;
  color: white;
  line-height:2.5;
}

header {
  background-color: black;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

nav {
  display: flex;
  justify-content: center;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  display: block;
  padding: 20px;
  text-decoration: none;
  color: white;
}

nav ul li a:hover {
  background-color: #333;
}

main {
  padding-top: 80px;
}

section {
  position: relative;
}

section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: 0.1rem;
  background-color: red;
}

section h2 {
  margin-bottom: 3rem;
}


.black {
  background-color: black;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
}

.hero h1 {
  font-size: 3rem;
}

.hero h2 {
  font-size: 2rem;
}

.about,
.delivery-zones,
.clients,
.total-meals,
.contact,
.services {
  padding: 50px 0;
}

.container {
  width: 80%;
  margin: auto;
}

.about h2,
.delivery-zones h2,
.clients h2,
.total-meals h2,
.contact h2,
.services h2 {
  text-align: center;
}

.client-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.client-logos img {
  margin: 20px;
  max-width: 150px;
}

.contact p {
  text-align: center;
  margin-bottom: 15px;
}

/* Style unordered lists */
ul {
  list-style: none;
  padding: 0;
}

/* Style list items */
li {
  margin-bottom: 10px; /* Add spacing between list items */
}

/* Style ordered lists */
ol {
  list-style: none;
  padding: 0;
}

/* Style list items with counters for ordered lists */
ol li {
  counter-increment: my-counter;
  margin-bottom: 10px; /* Add spacing between list items */
}

/* Style ordered list counters */
ol li::before {
  content: counter(my-counter) ".";
  margin-right: 5px; /* Add spacing between counter and list item text */
}

/* Style the About Us section content */
.about-content {
  text-align: justify; /* Justify text for better readability */
}

.about-content h3 {
  font-size: 1.5rem; /* Increase font size for subheadings */
  margin-top: 20px; /* Add spacing above subheadings */
}

.about-content p {
  font-size: 1rem; /* Set font size for paragraph text */
  line-height: 1.5; /* Set line height for better readability */
}

.footer{
    padding: 1rem;

}

/* Style the delivery zones list */
.delivery-zones ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}

.delivery-zones li {
  flex-basis: calc(33.33% - 10px); /* Adjust width of each item */
  margin-bottom: 20px; /* Add vertical spacing between items */
  padding: 10px; /* Add padding to each item */
  box-sizing: border-box; /* Include padding in item width calculation */
}

@media screen and (max-width: 768px) {
  .delivery-zones li {
    flex-basis: calc(50% - 10px); /* Adjust width of each item for smaller screens */
  }
}